import {createRouter, createWebHistory} from 'vue-router';


const routes = [
    {
        path: "/trunks",
        name: "Trunks",
        component: () => import("../components/TrunksList.vue")
    },
    {
        path: "/upload-excel",
        name: "UploadExcelTrunks",
        component: () => import("../components/UploadExcelTrunks.vue")
    },
    {
        path: "/service-information",
        name: "ListServicesInformation",
        component: () => import("../components/ServiceInformation/ListServicesInformation.vue"),
    },
    {
        path: "/service-information/",
        name: "routeServiceInformationLayout",
        component: () => import("../components/TheEmptyLayout.vue"),
        children: [
            {
                path: "/trunk-relate-services",
                name: "TrunkRelateServices",
                component: () => import("../components/ServiceInformation/TrunkRelateServices.vue")
            },
            {
                path: "/activate-message-logs",
                name: "ActivateServiceLogs",
                component: () => import("../components/ServiceInformation/ActivateMessagesLogs.vue")
            },
            {
                path: "/session-sms-customers",
                name: "SessionSmsCustomers",
                component: () => import("../components/ServiceInformation/SmsCustomerSessions.vue")
            },
            {
                path: "/temporary-switched-off-trunks",
                name: "TemporarySwitchedOffTrunks",
                component: () => import("../components/ServiceInformation/TemporarySwitchedOffTrunks.vue")
            },
        ]
    },
    {
        path: "/directory",
        name: "ListDirectories",
        component: () => import("../components/directories/DirectoriesList.vue")
    },
    {
        path: "/directory/",
        name: "routeDirectoriesLayout",
        component: () => import("../components/TheEmptyLayout.vue"),
        children: [
            {
                path: "customer-tariffs",
                name: "CustomerTariff",
                component: () => import("../components/directories/DirectoryCustomerTariffs.vue")
            },
            {
                path: "/operators",
                name: "Operator",
                component: () => import("../components/directories/DirectoryOperator.vue")
            },
            {
                path: "/tariffs-operators",
                name: "TariffOperator",
                component: () => import("../components/directories/DirectoryOperatorTariff.vue")
            },
            {
                path: "/countries",
                name: "Country",
                component: () => import("../components/directories/DirectoryCountry.vue")
            },
            {
                path: "/determining-phone-number",
                name: "ConstructorDeterminingPhoneNumber",
                component: () => import("../components/directories/Constructors/DeterminingPhoneNumber.vue")
            },
            {
                path: "/ussd-commands",
                name: "ConstructorUssdLayout",
                component: () => import("../components/TheEmptyLayout.vue"),
                children: [
                    {
                        path: "",
                        name: "ConstructorUssdCommands",
                       component: () => import("../components/directories/Constructors/UssdCommands.vue")
                    },
                    {
                        path: ":id/",
                        name: "EditConstructorUssdCommands",
                        component: () => import("../components/directories/Constructors/EditUssdCommands.vue")
                    },
                ],

            },
            {
                path: "/projects",
                name: "Projects",
                component: () => import("../components/directories/DirectoryProject.vue")
            },
            {
                path: "/services",
                name: "Services",
                component: () => import("../components/directories/DirectorySmsHubServices.vue")
            },
            {
                path: "/activate-tariffs",
                name: "ActivateTariffsLayout",
                component: () => import("../components/TheEmptyLayout.vue"),
                children: [
                    {
                        path: "",
                        name: "ActivateCustomerTariff",
                        component: () => import("../components/directories/ActivateTariffs/ActivateTariffs.vue")
                    },
                    {
                        path: ":tariff_id/",
                        name: "UpdateActivateCustomerTariff",
                        component: () => import("../components/directories/ActivateTariffs/ActivateTariffServices.vue")
                    },
                ],
            },

        ],
    },
    {
        path: "/activate-service-customers",
        name: "ActivateServiceCustomers",
        component: () => import("../components/ActivateService/ListCustomer.vue")
    },
    {
        path: "/customers",
        name: "Customers",
        component: () => import("../components/SmsCustomer/ListSmsCustomers.vue")
    },
    {
        path: "/customer/:customer_id/tariffs",
        name: "ListCustomerTariffs",
        component: () => import("../components/SmsCustomer/ListCustomerTariffs.vue")
    },
    {
        path: "/customer/:customer_id/history",
        name: "RequestLogCustomers",
        component: () => import("../components/SmsCustomer/SmsRequestLogCustomer.vue")
    },
    {
        path: "/transactions/",
        name: "TabsTransactions",
        component: () => import("../components/transactions/listTabs.vue"),
        children: [
            {
                path: "Pay001",
                name: "TransactionsListPay001",
                component: () => import("../components/transactions/ListPay001.vue"),
            },
            {
                path: "Pay002",
                name: "TransactionsListPay002",
                component: () => import("../components/transactions/ListPay002.vue"),
            },
            {
                path: "Pay003",
                name: "TransactionsListPay003",
                component: () => import("../components/transactions/ListPay003.vue"),
            },
            {
                path: "Pay003",
                name: "TransactionsListPay004",
                component: () => import("../components/transactions/ListPay004.vue"),
            },
        ]
    },
    {
        path: "/users",
        name: "CreateUser",
        component: () => import("../components/UsersList.vue")
    },
    {
        path: "/activate-service-billing",
        name: "ActivateServiceBilling",
        component: () => import("../components/ActivateServicesBilling.vue")
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
//   history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

export default router;
